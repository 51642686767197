import { defineStore } from 'pinia';
import type { Device } from '@/types/deviceTypes';

export const useDeviceStore = defineStore('devices', () => {
  const devices = ref<Device[]>([]);
  const deviceTotal = ref(0);
  const token = useCookie<string>('token')

  const getDevices = computed(() => devices.value);
  const getDeviceTotal = computed(() => deviceTotal.value);

  async function fetchDevice(deviceId: string) {
      const response = await $fetch.raw(`/api/v1/user/devices/${deviceId}`, {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      });
      return response._data as any;
  }

  async function fetchDevices(params: { limit: number; page: number, type?: string | null, roomId?: string | null, category?: string | null,serialNumber?: string | null }) {
      const response = await $fetch.raw('/api/v1/user/devices', {
        params: {
          limit: params.limit,
          page: params.page,
          type: params.type,
          roomId: params.roomId,
          category: params.category,
          serialNumber: params.serialNumber
        },
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      });
      devices.value = response._data as any;
      deviceTotal.value = parseInt(response.headers.get('X-Total-Items') || '');
  };

  async function fetchDevicesWithFilters(params: {fromTime: string;toTime: string;sortBy: 'name';sortOrder?: 'asc' | 'desc';name: string;}) {
    const response = await $fetch.raw('/api/v1/user/fmp/devices', {
      params: {
        fromTime: params.fromTime,
        toTime: params.toTime,
        sortBy: params.sortBy || 'name',
        sortOrder: params.sortOrder || 'asc',
        name: params.name,
      },
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });
    devices.value = response._data as any;
    deviceTotal.value = parseInt(response.headers.get('X-Total-Items') || '');
  }

  async function createDevice(device: Device) {
      const response = await $fetch('/api/v1/user/devices', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token.value}`
        },
        body: device
      });
      devices.value.push(response as any);
      deviceTotal.value += 1;
  };

  async function updateDevice(deviceId: string, payload: any) {
      const response = await $fetch(`/api/v1/user/devices/${deviceId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token.value}`
        },
        body: payload
      });
      return response
  };

  async function deleteDevice(deviceId: string) {
      await $fetch(`/api/v1/user/devices/${deviceId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token.value}`
        },
      });
      devices.value = devices.value.filter(device => device.id !== deviceId);
      deviceTotal.value -= 1;
  };

  return {
    devices,
    deviceTotal,
    getDevices,
    getDeviceTotal,
    fetchDevice,
    fetchDevices,
    createDevice,
    updateDevice,
    deleteDevice,
    fetchDevicesWithFilters
  };
});
